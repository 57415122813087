import axios from "axios";
import QueryString from "qs";

const instance = axios.create({
    baseURL:"https://gd.dofollowfx.cn/web",
    timeout:5000
})

instance.interceptors.request.use(
    config =>{
        if(config.method === "post"){
            config.data = QueryString.stringify(config.data)
        }
        return config
    },
    error =>{
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    response =>{
        return response.status === 200 ?
        Promise.resolve(response.data):Promise.reject(response)
    },
    error =>{
        const {response} = error
        console.log("返回 ==> " + response.status,response.info);
    }
)

export default instance;