<template>
  <div class="home">
    <div id="carouselbanner" class="carousel slide" data-bs-ride="carousel">
      <ol class="carousel-indicators">
        <li data-bs-target="#carouselbanner" v-for="(ban,index) in banners" :data-bs-slide-to="index" :class="index==0?'active':''"></li>
      </ol>
      <div class="carousel-inner" role="listbox">
        <div :class="index==0?'carousel-item active':'carousel-item'" v-for="(ban,index) in banners">
          <img :src="ban.url" class="w-100 d-block" alt="First slide">
          <div class="carousel-caption d-none d-md-block">
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselbanner" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselbanner" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    <section class="section-box text-black" :style='s1_bg'>
      <div class="container p-md-5">
        <div class="row text-center">
          <p class="text-18 text-red">热门交易员</p>
          <p class="text-40">共享交易让机会触手可及</p>
        </div>
        <div class="row justify-content-center align-items-center text-center pb-md-0">
          <div class="col-lg p-4" v-for="n in topsignals">
            <div class="mt-5">
              <div class="c-item shadow p-0" @click="goPersonal(n.id)">
                <div class="c-item-body">
                  <img v-if="n.headimg" class="c-item-name shadow btn-cursor" :src="n.headimg" @click="goPersonal(n.id)" alt="">
                  <div v-else class="c-item-name shadow btn-cursor" @click="goPersonal(n.id)">{{ n.name.substring(0, 2) }}</div>
                  <div class="p-md-2 pt-md-3 pb-md-0">
                    <div class="text-18" style="min-height: 78px">
                      <b>{{ n.name }}</b>                    
                      <div class="col">
                        <div class="text-16 text-red"><b>{{ n.profitrate }}%</b></div>
                        <p class="text-12 text-999">收益率</p>
                      </div>
                    </div>
                  </div>
                  <div class="row p-md-3 c-item-data">
                    <div class="col">
                      <div class="text-16"><b>${{ n.profit }}</b></div>
                      <p class="text-12 text-999">获利</p>
                    </div>
                    <div class="col">
                      <div class="text-16"><b>{{ n.winrate }}%</b></div>
                      <p class="text-12 text-999">胜率</p>
                    </div>
                    <div class="col">
                      <div class="text-16"><b>{{ n.maxdrawdown }}%</b></div>
                      <p class="text-12 text-999">最大回撤</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 p-0 text-end">
                      <button class="btn btn-danger masterCardRight" @click="goPersonal(n.id)" role="button">查看详情</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-box text-black" :style='s2_bg'>
      <div class="container p-md-5">
        <div class="row text-center">
          <p class="text-18 text-red">专业视频</p>
          <p class="text-40">外汇专业知识与视频</p>
        </div>
        <div class="row justify-content-center align-items-center text-center pb-md-0">
          <div class="col-lg p-4" v-for="n in topvideos">
            <div class="mt-5">
              <video-player   class="video-player vjs-custom-skin"
                          ref="videoPlayer"
                          :playsinline="true"
                          :options="getoption(n)"
              ></video-player>
              <p>{{n.title}}</p> 
            </div> 
          </div>
        </div>
        <div class="text-center pb-4">
          <button type="button" class="btn btn-dark btn-lg btn-200" @click="$com.go('/article')">更多视频</button>
        </div>
      </div>
    </section>
    <section class="section-box text-black" :style='s1_bg'>
      <div class="container p-md-5">
        <div class="row text-center p-5">
          <p class="text-18 text-red">全球领先的外汇智投社区</p>
          <span class="text-40">社区跟单 交流互动 一键跟投</span>
        </div>
        <div class="row justify-content-center align-items-center pb-4 pb-md-0">
          <div class="col-md-6 p-5 pt-0 p-md-0 order-md-last">
            <div class="row s-img-box shadow">
              <div class="img-title">
                <span class="text-red">TRADEPRO</span>
                <p class="text-28">Finance 4.0</p>
              </div>
              <img src="../assets/home-s1.png" alt="">
            </div>
          </div>
          <div class="col-md-6">
            <p class="text-40 text-center text-md-start">好汇阁达人与您携手共进</p>
            <p class="text-40 text-red text-center text-md-start">金融4.0时代</p>
            <div class="row mt-4 g-3">
              <div class="col-6">
                <p class="text-24">社区跟单</p>
                <p>社区顶级交易员一键跟投</p>
              </div>
              <div class="col-6">
                <p class="text-24">开放融合</p>
                <p>为您提供一站式综合金融解决方案</p>
              </div>
              <div class="col-6">
                <p class="text-24">开放共享</p>
                <p>全民开放，共享普惠金融的红利</p>
              </div>
              <div class="col-6">
                <p class="text-24">科技应用</p>
                <p>为企业和个人提供智能化科技服务</p>
              </div>
            </div>
            <div class="mt-4 text-center text-md-start">
              <button type="button" class="btn btn-dark btn-lg btn-200" @click="$com.goLogin">立即跟单</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-box text-black" :style='s2_bg'>
      <div class="container p-md-5">
        <div class="row text-center p-5">
          <p class="text-18 text-red">好汇阁达人跟单社区为什么可以如此吸引你</p>
          <p class="text-40">让稳定的盈利回报成为</p>
          <p class="text-40">生活的常态</p>
        </div>
        <div class="row justify-content-center align-items-center text-center pb-md-0">
          <div class=" col-lg-4">
            <div class="s2-img-box p-4">
              <img src="../assets/home-s2-1.png" alt="">
            </div>
            <div class="p-4">
              <p class="text-24">好汇阁达人生态圈</p>
              <p class="text-999">集全球外汇跟投交易、人工智能服务于一体的社区</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="s2-img-box p-5">
              <img src="../assets/home-s2-2.png" alt="">
            </div>
            <div class="p-4">
              <p class="text-24">严选经纪商</p>
              <p class="text-999">坚持外汇行业合规标准严格甄选合作经纪商，并公开经纪商的过往经纪商业绩数据</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="s2-img-box p-4">
              <img src="../assets/home-s2-3.png" alt="">
            </div>
            <div class="p-4">
              <p class="text-24">透明化 让用户安心跟投</p>
              <p class="text-999">将所有行业数据公开透明，并确保数据真实可靠，让用户安心跟投</p>
            </div>
          </div>
        </div>
        <div class="text-center pb-4">
          <button type="button" class="btn btn-dark btn-lg btn-200" @click="$com.goLogin">立即跟单</button>
        </div>
      </div>
    </section>
    <section class="section-box text-black" :style='s3_bg'>
      <div class="container p-md-5">
        <div class="row text-center p-5">
          <p class="text-18 text-red">好汇阁达人社交跟投</p>
          <p class="text-40 text-white">一个让人放心的外汇跟投服务社区</p>
        </div>
        <div class="row justify-content-center align-items-start pb-md-0">
          <div class="col-lg-4 mb-4" v-for="n in s3_items">
            <div class="card p-1">
              <img class="card-img-top" :src="n.img" alt="">
              <div class="card-body">
                <span class="s3-item-index">{{ n.id }}</span>
                <div class="p-3"></div>
                <h4 class="card-title">{{ n.title }}</h4>
                <p class="card-text text-666" style="min-height: 80px;">{{ n.desc }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-box text-black" :style='s4_bg'>
      <div class="container p-md-5">
        <div class="row text-center p-5">
          <div class="col-md-4">
            <div class="s4-fa-icon"><i class="fa fa-usd"></i></div>
            <p class="text-60 text-black">13,680k+</p>
            <p class="text-666">跟随用户总获利</p>
          </div>
          <div class="col-md-4">
            <div class="s4-fa-icon"><i class="fa fa-exchange"></i></div>
            <p class="text-60 text-black">3,758k+</p>
            <p class="text-666">交易手数</p>
          </div>
          <div class="col-md-4">
            <div class="s4-fa-icon"><i class="fa fa-users"></i></div>
            <p class="text-60 text-black">163,767+</p>
            <p class="text-666">社区跟随人数</p>
          </div>
        </div>
        <div class="row text-center">
          <p class="text-18 text-red">全球信赖</p>
          <p class="text-40 text-black">了解来自100多个国家/地区的数百万</p>
          <p class="text-40 text-black">投资者的评价</p>
        </div>
        <div class="p-lg-5">
          <div class="card s4-card">
            <div class="card-body">
              <h5 class="card-title text-24">
                专业的事交给专业的人，作为投资，我愿意将投资转化为一件更简单的事情，要做的就是筛选出真正优秀的交易员，并且跟随他。事实证明这种投资方式效果也不错。
              </h5>
              <div class="card-text mt-4 mt-md-5 row justify-content-center align-items-center">
                <div class="col-6 text-end">
                  <img width="65" height="65" src="../assets/home-s4-user.png" alt="">
                </div>
                <div class="col-6 p-0 text-start">
                  <div>Dropout</div>
                  <span class="s4-star">
                    <i class="fa fa-star" v-for="n in 5"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-box text-black">
      <div class="container p-md-5">
        <div class="row text-center p-5">
          <p class="text-18 text-red">我们的合作伙伴</p>
          <p class="text-40">值得信赖的合伙伙伴</p>
        </div>
        <div class="row justify-content-center align-items-center text-center bg-white pb-md-0">
          <div class="col-6 col-lg-3 s5-company" v-for="n in plats">
            <img class="figure-img" :src='n.imgurl' alt="">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  title: 'HomeView',
  data() {
    return {
      banners: [],
      topsignals: [],
      topvideos:[],
      plats:[],
      s1_bg:{
        backgroundImage:'url(' + require('../assets/home-s1-bg.png') + ')'
      },
      s2_bg:{
        backgroundImage:'url(' + require('../assets/home-s2-bg.jpg') + ')'
      },
      s3_bg:{
        backgroundImage:'url(' + require('../assets/home-s3-bg.png') + ')'
      },
      s4_bg:{
        backgroundImage:'url(' + require('../assets/home-s4-bg.png') + ')'
      },
      s3_items:[
        {id:'01',img:require('../assets/home-s3-1.jpg'),title:'好汇阁达人跟投系统',desc:'全球领先的跟投系统，订单信号同步向交易者账户及好汇阁达人发送，最快可达秒级'},
        {id:'02',img:require('../assets/home-s3-2.jpg'),title:'人工智能',desc:'好汇阁达人社区顺势推出智能投顾，让外汇量化交易变得更简单更智能'},
        {id:'03',img:require('../assets/home-s3-3.jpg'),title:'明星经纪人计划',desc:'好汇阁达人社区打破传统代理模式，增加多管道收入，实现全民经纪人模式'},
        {id:'04',img:require('../assets/home-s3-4.jpg'),title:'牛人交易员(信号)',desc:'带领普通投资者一起赚钱的外汇投资达人，我们称之为交易员。在好汇阁达人，交易员是个人荣耀和实力的象征'},
        {id:'05',img:require('../assets/home-s3-5.jpg'),title:'交易学堂',desc:'交易学堂揭秘挖外汇无限财富，聚集所有好汇阁达人，共同探索汇市财富秘诀'},
        {id:'06',img:require('../assets/home-s3-6.jpg'),title:'开放融合',desc:'社区跟投+人工智能+全民经纪人，开放模式打造全球领先外汇智投社区'}
      ],      
    }
  },
  mounted(){
    this.$http.getBanners().then(res=>{
      this.banners = res.data
    });
    this.$http.getTopSignals().then(res=>{
      this.topsignals = res.data
    });
    this.$http.getTopVideos().then(res=>{
      this.topvideos = res.data
    });
    this.$http.getPlatinfos().then(res=>{
      this.plats = res.data
    });
  },
  methods: {    
    goPersonal(pid) {
      this.$router.push({ name: 'personal', query: { id: pid } })
    },
    getoption(n) {
            return {
                playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                autoplay: false, //如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: "video/mp4",
                    src: n.url //url地址
                }],
                poster: n.firstimg,//你的封面地址
                // width: document.documentElement.clientWidth,
                notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true  //全屏按钮
                }
            };
        },
  }
}
</script>
<style scoped>
.section-box {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 400px;
  padding-bottom: 20px;
}
.s-img-box{
  width: 415px;
  max-width: 100%;
  margin: 0 auto;
  text-align: left;
  border: 1px solid #eee;
  background-color: #fff;
  border-radius: 20px;
}
.s-img-box .img-title{
  padding: 40px 40px 0 40px;
}
.s2-img-box{
  height: 200px;
}
.s3-item-index{
  display: inline-block;
  background-color: #e74242;
  color: #fff;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  margin-top: -50px;
  font-size: large;
}
.s4-fa-icon{
  display: inline-block;
  background-color: #e74242;
  color: #fff;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-bottom: 15px;
}
.s4-card{
  padding: 5% 10%;
  background-color: #e74242;
  color: #fff;
  text-align: center;
}
.s4-star{
  color: #ffa41c;
}
.s5-company{
  background-color: #fff;
}
.masterCardRight {
  margin-right:12px;
  border-radius: 0;
  width: 50%;
  -webkit-clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);
}

.c-item-name {
  display: inline-block;
  width: 110px;
  height: 110px;
  line-height: 90px;
  text-align: center;
  background-color: #e74242;
  border: 10px solid #fff;
  color: #fff;
  font-size: 24px;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: -50px
}
</style>
